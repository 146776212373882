import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Seo from 'components/SEO';
import { get } from 'lodash';
import {
  SidebarLayout,
  Sidebar,
  Content,
  Container,
  SidebarMenu,
  SidebarMenuButton,
  SidebarIcon,
  SidebarInner,
  SidebarContent,
  SidebarText,
  Button,
} from 'ui';
import question from 'images/renseignement.png';
import { MdChevronLeft, MdList } from 'react-icons/md';
import Faq from 'components/Faq';

const FAQ = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedFaq, setSelectedFaq] = useState(null);

  const faqs = get(data, 'allPrismicFaq.edges', []);
  const faqCategories = get(data, 'allPrismicFaqCategory.edges', []);

  const getNameFromState = () => {
    if (selectedCategory === 'all') {
      return 'Toutes les catégories';
    }
    const currentCategory = faqCategories.find(
      ({ node }) => node?.uid === selectedCategory,
    );
    return currentCategory?.node?.data?.name;
  };

  const selectedFaqs =
    selectedCategory === 'all'
      ? faqs
      : faqs.filter(
          ({ node }) =>
            get(node, 'data.category.document.uid') === selectedCategory,
        );

  return (
    <Layout>
      <Seo title="Questions fréquentes" />
      <Header title="J’ai une question" icon={question} />

      <SidebarLayout>
        <Sidebar>
          <SidebarContent>
            <SidebarInner>
              <Button as={Link} to="/" variant="primary">
                <MdChevronLeft /> Retour
              </Button>
            </SidebarInner>

            <SidebarMenu>
              <SidebarMenuButton
                isActive={selectedCategory === 'all'}
                onClick={() => setSelectedCategory('all')}
              >
                <MdList size={22} />
                Tous
              </SidebarMenuButton>
              {faqCategories.map(({ node }) => (
                <SidebarMenuButton
                  key={node?.uid}
                  isActive={selectedCategory === node?.uid}
                  onClick={() => setSelectedCategory(node?.uid)}
                >
                  <SidebarIcon src={node?.data?.icon?.url} alt="" />
                  <SidebarText>{node?.data?.name}</SidebarText>
                </SidebarMenuButton>
              ))}
            </SidebarMenu>
          </SidebarContent>
        </Sidebar>

        <Content>
          <Container position="left" paddingSize="large">
            <h1>{getNameFromState()}</h1>
            {selectedFaqs.map(({ node }) => (
              <Faq
                key={node?.id}
                faq={node?.data}
                id={node?.id}
                selectedFaq={selectedFaq}
                onSelectFaq={setSelectedFaq}
              />
            ))}
          </Container>
        </Content>
      </SidebarLayout>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicFaq {
      edges {
        node {
          id
          data {
            question
            answer {
              richText
            }
            category {
              document {
                ... on PrismicFaqCategory {
                  id
                  uid
                  data {
                    name
                    icon {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicFaqCategory {
      edges {
        node {
          uid
          data {
            icon {
              url
              alt
            }
            name
          }
        }
      }
    }
  }
`;

export default FAQ;
